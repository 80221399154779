<template>
   <div class="">
      <input 
         type="number" 
         v-model.number="amount"
         placeholder="Mínimo 5.00€"
         :min="5" 
         class="form-input w-full"
         @input="error = false"
      />

      <p 
         v-if="error"
         class="mt-2 px-3 text-sm text-red-500"
      >
         El depósito mínimo es de 5€
      </p>

      <div class="mt-8 relative">
         <div 
            id="paypal-button-container"
            :class="{ 'opacity-75': !amount || amount <= 0 }"
         ></div>
      </div>

      <div 
         v-if="loading"
         class="fixed inset-0 w-full h-full flex bg-gray-900 bg-opacity-50 z-50"
      >
         <div class="m-auto">
            <svg class="animate-spin h-20 w-20 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/></svg>            
         </div>
      </div>
   </div>
</template>

<script>
   import { onMounted, ref, watch } from 'vue';
   import Paypal from "@/api/endpoints/Paypal";

   import { alert } from '@/components/alerts';

   import emitter from "@/utils/emitter.js";

   export default {
      name: 'Paypal',
      setup() {
         const loading = ref(false);
         const error = ref(false);
         const amount = ref();

         onMounted(() => {
            const buttons = paypal.Buttons({
               onInit: function(data, actions) {
                  actions.disable();

                  watch(() => amount.value, (value) => {
                     if (value) {
                        actions.enable();
                     } else {
                        actions.disable();
                     }
                  });
               },
               onClick: async function (data, actions) {
                  if (amount.value < 5) {
                     actions.reject();
                     error.value = true;

                     return false;
                  }
               },
               createOrder: async function(data, actions) {
                  const response = await Paypal.create({
                     amount: parseFloat(amount.value)
                  });
                  return response.paypalId;
               },
               onApprove: async function(data, actions) {
                  loading.value = true;

                  const details = await actions.order.capture();
                  await Paypal.confirm({
                     id: details.id
                  });

                  emitter.emit('balance:change');

                  alert({
                     title: 'Pago realizado',
                     text: 'El pago a través de paypal se ha realizado correctamente!',
                     async confirm({ close }) {
                           close();
                     }
                  });

                  loading.value = false;
                  amount.value = null;
               }

            })
            
            buttons.render('#paypal-button-container');
         });

         return {
            loading,
            error,
            amount
         };
      }
   };
</script>