<template>
     <MainLayout>
        <div class="mt-16 px-6 py-4 flex-1 flex flex-col bg-white rounded-t-3xl">
            <div class="-mt-20">
							<Card :balance="employeeBalance"/>
            </div>

            <div class="mt-6">
                <h1 class="text-3xl text-gray-800 font-semibold">
                    Depósito
                </h1>

                <div class="mt-6">
                    <Paypal />
                </div>
            </div>
        </div>
     </MainLayout>
</template>

<script>    
    import MainLayout from '@/layouts/main';
    import Card from '/src/components/card';
    import Paypal from "/src/components/paypal/Paypal";
		import EmployeeBalance from '@/api/endpoints/EmployeeBalance';
		import { ref } from 'vue';
		import { state } from '@/store';

    export default {
        name: 'Deposit',
        components: {
            MainLayout,
            Card,
            Paypal
        },
        setup() {
					const employeeBalance = ref(0);
					async function getBalance() {
						employeeBalance.value = await EmployeeBalance.current(state.user.id);
					}
					getBalance();

					return{
						employeeBalance,
						getBalance
					}
        }
    }
</script>

<style>

</style>