<template>
    <div class="w-full aspect-w-16 aspect-h-9">
        <div class="p-6 w-full h-full flex flex-col bg-gradient-to-tr from-indigo-500 to-pink-300 rounded-xl shadow-xl">
            <div class="flex justify-between items-center">
                <p class="text-white">Sesame Wallet</p>
                <img 
                    src="@/assets/images/sesame-white.svg"
                    class="w-10 h-10"
                />
            </div>
            <div class="mt-auto flex justify-between items-center">
                <div class="text-white">
                    <p class="text-xs">Saldo</p>
                    <p class="text-2xl font-bold">{{ balance.toFixed(2) }}€</p>
                </div>

                <router-link
                    :to="{ name: 'Deposit' }"
                    custom
                    v-slot="{ navigate, isActive }"
                >
                    <button
                        v-if="!isActive"
                        class="px-2 py-2 text-white bg-white bg-opacity-25 rounded-xl"
                        @click="navigate"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/></svg>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Card',
				props:{
					balance:{
						type: [Number],
						default: 0
					}
				},
    }
</script>