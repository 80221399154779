import EndpointPrivate from "../EndpointPrivate";

class Order extends EndpointPrivate {
	buildUrl(request) {
		return ['food', 'v1', 'paypal-orders', request.param];
	}

	confirm(form) {
		return this.update(form);
	}
};

export default new Order;

