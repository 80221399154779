import EndpointPrivate from '../EndpointPrivate.js';

class Pay extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'employees', param, 'balances'];		
	}

	current(param) {
		return this.request({
			url: `food/v1/employees/${param}/current-balance`,
			action: 'current-balance',
			method: 'GET',
		});
	}

	refund(attributes) {
		return this.request({
			url: `/food/v1/refund-balances/${this.primaryKey(attributes)}`,
			method: 'PUT',
			data: attributes
		});
	}
}

export default new Pay;